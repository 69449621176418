import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/index'
  },
  {
    path: '/index',
    meta: {
        title: 'itunes 链接 - appstare',
        viewport: 'width=device-width,initial-scale=1,maximum-scale=1,user-scalable=no'
    },
    component: () => import('../views/appToday/appToday'),
    hidden: true
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
