<template>
    <div id="appToday">
        <div class="app-today-container">
            <div class="search">
                <el-input
                    v-model="state1"
                    placeholder="请输入搜索国家"
                    class="inline-input"
                    @input="querySearch"
                ></el-input>
                <div class="country-array" v-if="countryArray.length > 0">
                    <div class="country-item" v-for="(item, index) in countryArray" :key="index + item.country">
                        <div v-if="userStatus" class="country-item" @click="skip(item.dsf)">
                            <img :src="item.src" width="24" :alt="item.countryEN" class="icon" />
                            <span class="text">{{ item.country }}</span>
                        </div>
                        <div v-else class="country-item" @click="showMessage">
                            <img :src="item.src" :alt="item" class="icon" />
                            <span class="text">{{ item.country }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <h2 class="header">{{ lang === 'EN' ? titleEN : title }}</h2>
            <div class="language">
                <span class="item" :class="lang === '中' ? 'active' : ''" @click="changeLanguage('中')">中</span>
                <span class="item" :class="lang === 'EN' ? 'active' : ''" @click="changeLanguage('EN')">EN</span>
            </div>

            <div class="content">
                <div class="title">
                    {{ lang === 'EN' ? hotCountries.titleEN : hotCountries.title }}
                </div>
                <ul class="country-list">
                    <li v-for="country in hotCountries.list" :key="country.dsf">
                        <div @click="skip(country.dsf)" v-if="userStatus">
                            <img :src="country.src" width="24" :alt="country.countryEN" class="country-flag" />
                            <span>{{ lang === 'EN' ? country.countryEN : country.country }}</span>
                            <i class="ad-flag" v-if="country.ad"></i>
                        </div>
                        <div v-else @click="showMessage">
                            <img :src="country.src" width="24" :alt="country.countryEN" class="country-flag" />
                            <span>{{ lang === 'EN' ? country.countryEN : country.country }}</span>
                            <i class="ad-flag" v-if="country.ad"></i>
                        </div>
                    </li>
                </ul>
            </div>

            <div class="content" v-for="item in countries" :key="item.title">
                <div class="title">{{ lang === 'EN' ? item.titleEN : item.title }}</div>
                <ul class="country-list">
                    <li v-for="(country, index) in item.list" :key="'country' + index">
                        <div @click="skip(country.dsf)" v-if="userStatus">
                            <img :src="country.src" width="24" :alt="country.countryEN" class="country-flag" />
                            <span>{{ lang === 'EN' ? country.countryEN : country.country }}</span>
                            <i class="ad-flag" v-if="country.ad"></i>
                        </div>
                        <div v-else @click="showMessage">
                            <img :src="country.src" width="24" :alt="country.countryEN" class="country-flag" />
                            <span>{{ lang === 'EN' ? country.countryEN : country.country }}</span>
                            <i class="ad-flag" v-if="country.ad"></i>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <footer class="footer">
            <div class="copyright">
                <div class="container">
                    © 2019 凝视数科（北京）科技有限公司<span class="pl15"
                        ><a href="https://beian.miit.gov.cn" target="_blank">京ICP备19015157号</a></span
                    >
                </div>
            </div>
        </footer>

        <!-- 在苹果的App Store中，每个国家或地区有一个唯一的数字代码，用于标识。这些代码通常称为“Storefront ID”或“Store ID”。以下是一些常见国家或地区的示例代码：

        美国：143441
        中国大陆：143465
        加拿大：143455
        澳大利亚：143460
        英国：143444 -->
    </div>
</template>
<script>
    import pinyin from 'pinyin-match';
    export default {
        name: 'AppToday',
        data() {
            return {
                countryArray: [],
                restaurants: [],
                state1: '',
                state2: '',
                lang: '中',
                userStatus: true,
                title: '选择国家或地区',
                titleEN: 'Choose your country or region',
                hotCountries: {
                    title: '热门国家或地区',
                    titleEN: 'Popular countries / regions',
                    list: [
                        {
                            src: require('@/assets/countriesLogo/us.svg'),
                            dsf: 143441,
                            country: '美国',
                            countryEN: 'USA',
                            ad: true,
                        },
                        {
                            src: require('@/assets/countriesLogo/gb.svg'),
                            dsf: 143444,
                            country: '英国',
                            countryEN: 'UK',
                            ad: true,
                        },
                        {
                            src: require('@/assets/countriesLogo/de.svg'),
                            dsf: 143443,
                            country: '德国',
                            countryEN: 'Germany',
                            ad: true,
                        },
                        {
                            src: require('@/assets/countriesLogo/fr.svg'),
                            dsf: 143442,
                            country: '法国',
                            countryEN: 'France',
                            ad: true,
                        },
                        {
                            src: require('@/assets/countriesLogo/jp.svg'),
                            dsf: 143462,
                            country: '日本',
                            countryEN: 'Japan',
                            ad: true,
                        },
                        {
                            src: require('@/assets/countriesLogo/hk.svg'),
                            dsf: 143463,
                            country: '香港',
                            countryEN: 'Hong Kong',
                            ad: true,
                        },
                        {
                            src: require('@/assets/countriesLogo/mo.svg'),
                            dsf: 143515,
                            country: '澳门',
                            countryEN: 'Macau',
                            ad: true,
                        },
                        {
                            src: require('@/assets/countriesLogo/tw.svg'),
                            dsf: 143470,
                            country: '台湾',
                            countryEN: 'Taiwan',
                            ad: true,
                        },
                        {
                            src: require('@/assets/countriesLogo/ph.svg'),
                            dsf: 143474,
                            country: '菲律宾',
                            countryEN: 'Philippines',
                            ad: true,
                        },
                        {
                            src: require('@/assets/countriesLogo/sg.svg'),
                            dsf: 143464,
                            country: '新加坡',
                            countryEN: 'Singapore',
                            ad: true,
                        },
                        {
                            src: require('@/assets/countriesLogo/sa.svg'),
                            dsf: 143479,
                            country: '沙特阿拉伯',
                            countryEN: 'Saudi Arabia',
                            ad: true,
                        },
                        {
                            src: require('@/assets/countriesLogo/cn.svg'),
                            dsf: 143465,
                            country: '中国',
                            countryEN: 'China',
                            ad: true,
                        },
                    ],
                },
                countries: [
                    {
                        title: '南非、中东及印度',
                        titleEN: 'Africa, Middle East, and India',
                        list: [
                            {
                                src: require('@/assets/countriesLogo/dz.svg'),
                                dsf: 143563,
                                country: '阿尔及利亚',
                                countryEN: 'Algeria',
                            },
                            {
                                src: require('@/assets/countriesLogo/ao.svg'),
                                dsf: 143564,
                                country: '安哥拉',
                                countryEN: 'Angola',
                            },
                            {
                                src: require('@/assets/countriesLogo/am.svg'),
                                dsf: 143524,
                                country: '亚美尼亚',
                                countryEN: 'Armenia',
                            },
                            {
                                src: require('@/assets/countriesLogo/az.svg'),
                                dsf: 143568,
                                country: '阿塞拜疆',
                                countryEN: 'Azerbaijan',
                                ad: true,
                            },
                            {
                                src: require('@/assets/countriesLogo/bh.svg'),
                                dsf: 143559,
                                country: '巴林',
                                countryEN: 'Bahrain',
                            },
                            {
                                src: require('@/assets/countriesLogo/bj.png'),
                                //   dsf: 1111,
                                dsf: 143576,
                                country: '贝宁',
                                countryEN: 'Benin',
                            },
                            {
                                src: require('@/assets/countriesLogo/bw.svg'),
                                dsf: 143525,
                                country: '博茨瓦纳',
                                countryEN: 'Botswana',
                            },
                            {
                                src: require('@/assets/countriesLogo/bf.png'),
                                // dsf: 111,
                                dsf: 143578,
                                country: '布基纳法索',
                                countryEN: 'Burkina-Faso',
                            },
                            {
                                src: require('@/assets/countriesLogo/cv.png'),
                                // dsf: 111,
                                dsf: 143580,
                                country: '佛得角',
                                countryEN: 'Cape Verde',
                            },
                            {
                                src: require('@/assets/countriesLogo/td.png'),
                                // dsf: 111,
                                dsf: 143581,
                                country: '乍得',
                                countryEN: 'Chad',
                            },
                            {
                                src: require('@/assets/countriesLogo/cg.png'),
                                // dsf: 111,
                                dsf: 143582,
                                country: '刚果（布）',
                                countryEN: 'Republic of the Congo',
                            },
                            {
                                src: require('@/assets/countriesLogo/eg.svg'),
                                dsf: 143516,
                                country: '埃及',
                                countryEN: 'Egypt',
                                ad: true,
                            },
                            {
                                src: require('@/assets/countriesLogo/gm.png'),
                                // dsf: 111,
                                dsf: 143584,
                                country: '冈比亚',
                                countryEN: 'Gambia',
                            },
                            {
                                src: require('@/assets/countriesLogo/gh.svg'),
                                dsf: 143573,
                                country: '加纳',
                                countryEN: 'Ghana',
                            },
                            {
                                src: require('@/assets/countriesLogo/gw.png'),
                                // dsf: 111,
                                dsf: 143585,
                                country: '几内亚比绍',
                                countryEN: 'Guinea-Bissau',
                            },
                            {
                                src: require('@/assets/countriesLogo/in.svg'),
                                dsf: 143467,
                                country: '印度',
                                countryEN: 'India',
                                ad: true,
                            },
                            {
                                src: require('@/assets/countriesLogo/il.svg'),
                                dsf: 143491,
                                country: '以色列',
                                countryEN: 'Israel',
                                ad: true,
                            },
                            {
                                src: require('@/assets/countriesLogo/jo.svg'),
                                dsf: 143528,
                                country: '约旦',
                                countryEN: 'Jordan',
                                ad: true,
                            },
                            {
                                src: require('@/assets/countriesLogo/ke.svg'),
                                dsf: 143529,
                                country: '肯尼亚',
                                countryEN: 'Kenya',
                            },
                            {
                                src: require('@/assets/countriesLogo/kw.svg'),
                                dsf: 143493,
                                country: '科威特',
                                countryEN: 'Kuwait',
                                ad: true,
                            },
                            {
                                src: require('@/assets/countriesLogo/lb.svg'),
                                dsf: 143497,
                                country: '黎巴嫩',
                                countryEN: 'Lebanon',
                                ad: true,
                            },
                            {
                                src: require('@/assets/countriesLogo/lr.png'),
                                // dsf: 111,
                                dsf: 143588,
                                country: '利比里亚',
                                countryEN: 'Liberia',
                            },
                            {
                                src: require('@/assets/countriesLogo/mg.svg'),
                                dsf: 143531,
                                country: '马达加斯加',
                                countryEN: 'Madagascar',
                            },
                            {
                                src: require('@/assets/countriesLogo/mw.png'),
                                // dsf: 111,
                                dsf: 143589,
                                country: '马拉维',
                                countryEN: 'Malawi',
                            },
                            {
                                src: require('@/assets/countriesLogo/ml.svg'),
                                dsf: 143532,
                                country: '马里',
                                countryEN: 'Mali',
                            },
                            {
                                src: require('@/assets/countriesLogo/mr.png'),
                                // dsf: 111,
                                dsf: 143590,
                                country: '毛里塔尼亚',
                                countryEN: 'Mauritania',
                            },
                            {
                                src: require('@/assets/countriesLogo/mu.svg'),
                                dsf: 143533,
                                country: '毛里求斯',
                                countryEN: 'Mauritius',
                            },
                            {
                                src: require('@/assets/countriesLogo/mz.png'),
                                // dsf: 111,
                                dsf: 143593,
                                country: '莫桑比克',
                                countryEN: 'Mozambique',
                            },
                            {
                                src: require('@/assets/countriesLogo/na.png'),
                                // dsf: 111,
                                dsf: 143594,
                                country: '纳米比亚',
                                countryEN: 'Namibia',
                            },
                            {
                                src: require('@/assets/countriesLogo/ne.svg'),
                                dsf: 143534,
                                country: '尼日尔',
                                countryEN: 'Niger',
                            },
                            {
                                src: require('@/assets/countriesLogo/ng.svg'),
                                dsf: 143561,
                                country: '尼日利亚',
                                countryEN: 'Nigeria',
                            },
                            {
                                src: require('@/assets/countriesLogo/om.svg'),
                                dsf: 143562,
                                country: '阿曼',
                                countryEN: 'Oman',
                                ad: true,
                            },
                            {
                                src: require('@/assets/countriesLogo/qa.svg'),
                                dsf: 143498,
                                country: '卡塔尔',
                                countryEN: 'Qatar',
                                ad: true,
                            },
                            {
                                src: require('@/assets/countriesLogo/st.png'),
                                // dsf: 111,
                                dsf: 143598,
                                country: '圣多美和普林西比',
                                countryEN: 'São Tomé and Príncipe',
                            },
                            {
                                src: require('@/assets/countriesLogo/sa.svg'),
                                dsf: 143479,
                                country: '沙特阿拉伯',
                                countryEN: 'Saudi Arabia',
                                ad: true,
                            },
                            {
                                src: require('@/assets/countriesLogo/sn.svg'),
                                dsf: 143535,
                                country: '塞内加尔',
                                countryEN: 'Senegal',
                            },
                            {
                                src: require('@/assets/countriesLogo/sc.png'),
                                // dsf: 111,
                                dsf: 143599,
                                country: '塞舌尔',
                                countryEN: 'Seychelles',
                            },
                            {
                                src: require('@/assets/countriesLogo/sl.png'),
                                // dsf: 111,
                                dsf: 143600,
                                country: '塞拉利昂',
                                countryEN: 'Sierra Leone',
                            },
                            {
                                src: require('@/assets/countriesLogo/za.svg'),
                                dsf: 143472,
                                country: '南非',
                                countryEN: 'South Africa',
                                ad: true,
                            },
                            {
                                src: require('@/assets/countriesLogo/sz.png'),
                                // dsf: 111,
                                dsf: 143602,
                                country: '斯威士兰',
                                countryEN: 'Swaziland',
                            },
                            {
                                src: require('@/assets/countriesLogo/tz.svg'),
                                dsf: 143572,
                                country: '坦桑尼亚',
                                countryEN: 'Tanzania',
                            },
                            {
                                src: require('@/assets/countriesLogo/tn.svg'),
                                dsf: 143536,
                                country: '突尼斯',
                                countryEN: 'Tunisia',
                            },
                            {
                                src: require('@/assets/countriesLogo/ug.svg'),
                                dsf: 143537,
                                country: '乌干达',
                                countryEN: 'Uganda',
                            },
                            {
                                src: require('@/assets/countriesLogo/ae.svg'),
                                dsf: 143481,
                                country: '阿拉伯联合酋长国',
                                countryEN: 'United Arab Emirates',
                                ad: true,
                            },
                            {
                                src: require('@/assets/countriesLogo/ye.svg'),
                                dsf: 143571,
                                country: '也门',
                                countryEN: 'Yemen',
                            },
                            {
                                src: require('@/assets/countriesLogo/zw.png'),
                                // dsf: 111,
                                dsf: 143605,
                                country: '津巴布韦',
                                countryEN: 'Zimbabwe',
                            },
                        ],
                    },
                    {
                        title: '亚太地区',
                        titleEN: 'Asia Pacific',
                        list: [
                            {
                                src: require('@/assets/countriesLogo/iq.webp'),
                                // dsf: 143563, 此处原来有错误
                                dsf: 143617,
                                country: '伊拉克',
                                countryEN: 'Iraq',
                            },
                            {
                                src: require('@/assets/countriesLogo/mm.svg'),
                                // dsf: 111, 暂定为
                                dsf: 143570,
                                country: '缅甸',
                                countryEN: 'Myanmar',
                            },
                            {
                                src: require('@/assets/countriesLogo/au.svg'),
                                dsf: 143460,
                                country: '澳大利亚',
                                countryEN: 'Australia',
                                ad: true,
                            },
                            {
                                src: require('@/assets/countriesLogo/bt.png'),
                                // dsf: 111,
                                dsf: 143577,
                                country: '不丹',
                                countryEN: 'Bhutan',
                            },
                            {
                                src: require('@/assets/countriesLogo/bn.svg'),
                                dsf: 143560,
                                country: '文莱',
                                countryEN: 'Brunei Darussalam',
                            },
                            {
                                src: require('@/assets/countriesLogo/kh.svg'),
                                //   dsf: 111,
                                dsf: 143579,
                                country: '柬埔寨',
                                countryEN: 'Cambodia',
                                ad: true,
                            },
                            {
                                src: require('@/assets/countriesLogo/cn.svg'),
                                dsf: 143465,
                                country: '中国',
                                countryEN: 'China',
                                ad: true,
                            },
                            {
                                src: require('@/assets/countriesLogo/fj.png'),
                                // dsf: 111,
                                dsf: 143583,
                                country: '斐济',
                                countryEN: 'Fiji',
                            },
                            {
                                src: require('@/assets/countriesLogo/hk.svg'),
                                dsf: 143463,
                                country: '香港',
                                countryEN: 'Hong Kong',
                                ad: true,
                            },
                            {
                                src: require('@/assets/countriesLogo/id.svg'),
                                dsf: 143476,
                                country: '印度尼西亚',
                                countryEN: 'Indonesia',
                                ad: true,
                            },
                            {
                                src: require('@/assets/countriesLogo/jp.svg'),
                                dsf: 143462,
                                country: '日本',
                                countryEN: 'Japan',
                                ad: true,
                            },
                            {
                                src: require('@/assets/countriesLogo/kz.svg'),
                                dsf: 143517,
                                country: '哈萨克斯坦',
                                countryEN: 'Kazakstan',
                                ad: true,
                            },
                            {
                                src: require('@/assets/countriesLogo/kr.svg'),
                                dsf: 143466,
                                country: '韩国',
                                countryEN: 'Korea',
                                ad: true,
                            },
                            {
                                src: require('@/assets/countriesLogo/kg.png'),
                                // dsf: 111,
                                dsf: 143586,
                                country: '吉尔吉斯斯坦',
                                countryEN: 'Kyrgyzstan',
                            },
                            {
                                src: require('@/assets/countriesLogo/mo.svg'),
                                dsf: 143515,
                                country: '澳门',
                                countryEN: 'Macau',
                                ad: true,
                            },
                            {
                                src: require('@/assets/countriesLogo/my.svg'),
                                dsf: 143473,
                                country: '马来西亚',
                                countryEN: 'Malaysia',
                                ad: true,
                            },
                            {
                                src: require('@/assets/countriesLogo/fm.png'),
                                // dsf: 111,
                                dsf: 143591,
                                country: '密克罗尼西亚联邦',
                                countryEN: 'Micronesia, Fed States Of',
                            },
                            {
                                src: require('@/assets/countriesLogo/mn.png'),
                                // dsf: 111,
                                dsf: 143592,
                                country: '外蒙古',
                                countryEN: 'Mongolia',
                            },
                            {
                                src: require('@/assets/countriesLogo/np.svg'),
                                dsf: 143484,
                                country: '尼泊尔',
                                countryEN: 'Nepal',
                            },
                            {
                                src: require('@/assets/countriesLogo/nz.svg'),
                                dsf: 143461,
                                country: '新西兰',
                                countryEN: 'New Zealand',
                                ad: true,
                            },
                            {
                                src: require('@/assets/countriesLogo/pk.svg'),
                                dsf: 143477,
                                country: '巴基斯坦',
                                countryEN: 'Pakistan',
                                ad: true,
                            },
                            {
                                src: require('@/assets/countriesLogo/pw.png'),
                                // dsf: 111,
                                dsf: 143595,
                                country: '帕劳',
                                countryEN: 'Palau',
                            },
                            {
                                src: require('@/assets/countriesLogo/pg.png'),
                                // dsf: 111,
                                dsf: 143597,
                                country: '巴布亚新几内亚',
                                countryEN: 'Papua New Guinea',
                            },
                            {
                                src: require('@/assets/countriesLogo/ph.svg'),
                                dsf: 143474,
                                country: '菲律宾',
                                countryEN: 'Philippines',
                                ad: true,
                            },
                            {
                                src: require('@/assets/countriesLogo/sg.svg'),
                                dsf: 143464,
                                country: '新加坡',
                                countryEN: 'Singapore',
                                ad: true,
                            },
                            {
                                //国家图片原来展示的是新加坡
                                src: require('@/assets/countriesLogo/sb.svg'),
                                // dsf: 111,
                                // dsf: 143464,此处原来有错误
                                dsf: 143601,
                                country: '所罗门群岛',
                                countryEN: 'Solomon Islands',
                            },
                            {
                                src: require('@/assets/countriesLogo/lk.svg'),
                                dsf: 143486,
                                country: '斯里兰卡',
                                countryEN: 'Sri Lanka',
                            },
                            {
                                src: require('@/assets/countriesLogo/tw.svg'),
                                dsf: 143470,
                                country: '台湾',
                                countryEN: 'Taiwan',
                                ad: true,
                            },
                            {
                                src: require('@/assets/countriesLogo/tj.png'),
                                // dsf: 111,
                                dsf: 143603,
                                country: '塔吉克斯坦',
                                countryEN: 'Tajikistan',
                            },
                            {
                                src: require('@/assets/countriesLogo/th.svg'),
                                dsf: 143475,
                                country: '泰国',
                                countryEN: 'Thailand',
                                ad: true,
                            },
                            {
                                src: require('@/assets/countriesLogo/tm.png'),
                                // dsf: 111,
                                dsf: 143604,
                                country: '土库曼斯坦',
                                countryEN: 'Turkmenistan',
                            },
                            {
                                src: require('@/assets/countriesLogo/uz.svg'),
                                dsf: 143566,
                                country: '乌兹别克斯坦',
                                countryEN: 'Uzbekistan',
                            },
                            {
                                src: require('@/assets/countriesLogo/vn.svg'),
                                dsf: 143471,
                                country: '越南',
                                countryEN: 'Vietnam',
                                ad: true,
                            },
                            {
                                src: require('@/assets/countriesLogo/default.png'),
                                dsf: 111,
                                country: 'Other Asia',
                                countryEN: 'Other Asia',
                            },
                        ],
                    },
                    {
                        title: '欧洲',
                        titleEN: 'Europe',
                        list: [
                            {
                                src: require('@/assets/countriesLogo/al.png'),
                                // dsf: 111,
                                dsf: 143575,
                                country: '阿尔巴尼亚',
                                countryEN: 'Albania',
                                ad: true,
                            },
                            {
                                src: require('@/assets/countriesLogo/by.svg'),
                                dsf: 143565,
                                country: '白俄罗斯',
                                countryEN: 'Belarus',
                            },
                            {
                                src: require('@/assets/countriesLogo/be.svg'),
                                dsf: 143446,
                                country: '比利时',
                                countryEN: 'Belgium',
                                ad: true,
                            },
                            {
                                src: require('@/assets/countriesLogo/bg.svg'),
                                dsf: 143526,
                                country: '保加利亚',
                                countryEN: 'Bulgaria',
                            },
                            {
                                src: require('@/assets/countriesLogo/hr.svg'),
                                dsf: 143494,
                                country: '克罗地亚',
                                countryEN: 'Croatia',
                                ad: true,
                            },
                            {
                                src: require('@/assets/countriesLogo/cz.svg'),
                                dsf: 143489,
                                country: '捷克共和国',
                                countryEN: 'Czechia',
                                ad: true,
                            },
                            {
                                src: require('@/assets/countriesLogo/cy.svg'),
                                dsf: 143557,
                                country: '塞浦路斯',
                                countryEN: 'Cyprus',
                            },
                            {
                                src: require('@/assets/countriesLogo/dk.svg'),
                                dsf: 143458,
                                country: '丹麦',
                                countryEN: 'Denmark',
                                ad: true,
                            },
                            {
                                src: require('@/assets/countriesLogo/de.svg'),
                                dsf: 143443,
                                country: '德国',
                                countryEN: 'Germany',
                                ad: true,
                            },
                            {
                                src: require('@/assets/countriesLogo/es.svg'),
                                dsf: 143454,
                                country: '西班牙',
                                countryEN: 'Spain',
                                ad: true,
                            },
                            {
                                src: require('@/assets/countriesLogo/ee.svg'),
                                dsf: 143518,
                                country: '爱沙尼亚',
                                countryEN: 'Estonia',
                            },
                            {
                                src: require('@/assets/countriesLogo/fr.svg'),
                                dsf: 143442,
                                country: '法国',
                                countryEN: 'France',
                                ad: true,
                            },
                            {
                                src: require('@/assets/countriesLogo/gr.svg'),
                                dsf: 143448,
                                country: '希腊',
                                countryEN: 'Greece',
                                ad: true,
                            },
                            {
                                src: require('@/assets/countriesLogo/is.svg'),
                                dsf: 143558,
                                country: '冰岛',
                                countryEN: 'Iceland',
                            },
                            {
                                src: require('@/assets/countriesLogo/ie.svg'),
                                dsf: 143449,
                                country: '爱尔兰',
                                countryEN: 'Ireland',
                                ad: true,
                            },
                            {
                                src: require('@/assets/countriesLogo/it.svg'),
                                dsf: 143450,
                                country: '意大利',
                                countryEN: 'Italy',
                                ad: true,
                            },
                            {
                                src: require('@/assets/countriesLogo/lv.svg'),
                                dsf: 143519,
                                country: '拉脱维亚',
                                countryEN: 'Latvia',
                            },
                            {
                                src: require('@/assets/countriesLogo/lt.svg'),
                                dsf: 143520,
                                country: '立陶宛',
                                countryEN: 'Lithuania',
                            },
                            {
                                src: require('@/assets/countriesLogo/lu.svg'),
                                dsf: 143451,
                                country: '卢森堡',
                                countryEN: 'Luxembourg',
                            },
                            {
                                src: require('@/assets/countriesLogo/mk.svg'),
                                dsf: 143530,
                                country: '北马其顿',
                                countryEN: 'Macedonia',
                            },
                            {
                                src: require('@/assets/countriesLogo/hu.svg'),
                                dsf: 143482,
                                country: '匈牙利',
                                countryEN: 'Hungary',
                                ad: true,
                            },
                            {
                                src: require('@/assets/countriesLogo/mt.svg'),
                                dsf: 143521,
                                country: '马耳他',
                                countryEN: 'Malta',
                            },
                            {
                                src: require('@/assets/countriesLogo/md.svg'),
                                dsf: 143523,
                                country: '摩尔多瓦',
                                countryEN: 'Moldova',
                            },
                            {
                                src: require('@/assets/countriesLogo/nl.svg'),
                                dsf: 143452,
                                country: '荷兰',
                                countryEN: 'Netherlands',
                                ad: true,
                            },
                            {
                                src: require('@/assets/countriesLogo/no.svg'),
                                dsf: 143457,
                                country: '挪威',
                                countryEN: 'Norway',
                                ad: true,
                            },
                            {
                                src: require('@/assets/countriesLogo/at.svg'),
                                dsf: 143445,
                                country: '奥地利',
                                countryEN: 'Austria',
                                ad: true,
                            },
                            {
                                src: require('@/assets/countriesLogo/pl.svg'),
                                dsf: 143478,
                                country: '波兰',
                                countryEN: 'Poland',
                                ad: true,
                            },
                            {
                                src: require('@/assets/countriesLogo/pt.svg'),
                                dsf: 143453,
                                country: '葡萄牙',
                                countryEN: 'Portugal',
                                ad: true,
                            },
                            {
                                src: require('@/assets/countriesLogo/ro.svg'),
                                dsf: 143487,
                                country: '罗马尼亚',
                                countryEN: 'Romania',
                                ad: true,
                            },
                            {
                                src: require('@/assets/countriesLogo/ru.svg'),
                                dsf: 143469,
                                country: '俄罗斯',
                                countryEN: 'Russia',
                                ad: true,
                            },
                            {
                                src: require('@/assets/countriesLogo/ch.svg'),
                                dsf: 143459,
                                country: '瑞士',
                                countryEN: 'Switzerland',
                                ad: true,
                            },
                            {
                                src: require('@/assets/countriesLogo/sk.svg'),
                                dsf: 143496,
                                country: '斯洛伐克',
                                countryEN: 'Slovakia',
                            },
                            {
                                src: require('@/assets/countriesLogo/si.svg'),
                                dsf: 143499,
                                country: '斯洛文尼亚',
                                countryEN: 'Slovenia',
                            },
                            {
                                src: require('@/assets/countriesLogo/fi.svg'),
                                dsf: 143447,
                                country: '芬兰',
                                countryEN: 'Finland',
                                ad: true,
                            },
                            {
                                src: require('@/assets/countriesLogo/se.svg'),
                                dsf: 143456,
                                country: '瑞典',
                                countryEN: 'Sweden',
                                ad: true,
                            },
                            {
                                src: require('@/assets/countriesLogo/tr.svg'),
                                dsf: 143480,
                                country: '土耳其',
                                countryEN: 'Turkey',
                            },
                            {
                                src: require('@/assets/countriesLogo/gb.svg'),
                                dsf: 143444,
                                country: '英国',
                                countryEN: 'UK',
                                ad: true,
                            },
                            {
                                src: require('@/assets/countriesLogo/ua.svg'),
                                dsf: 143492,
                                country: '乌克兰',
                                countryEN: 'Ukraine',
                                ad: true,
                            },
                        ],
                    },
                    {
                        title: '拉丁美洲和加勒比地区',
                        titleEN: 'Latin America and the Caribbean',
                        list: [
                            {
                                src: require('@/assets/countriesLogo/ai.svg'),
                                dsf: 143538,
                                country: '安圭拉',
                                countryEN: 'Anguilla',
                            },
                            {
                                src: require('@/assets/countriesLogo/ag.svg'),
                                dsf: 143540,
                                country: '安提瓜和巴布达',
                                countryEN: 'Antigua & Barbuda',
                            },
                            {
                                src: require('@/assets/countriesLogo/ar.svg'),
                                dsf: 143505,
                                country: '阿根廷',
                                countryEN: 'Argentina',
                                ad: true,
                            },
                            {
                                src: require('@/assets/countriesLogo/bs.svg'),
                                dsf: 143539,
                                country: '巴哈马',
                                countryEN: 'Bahamas',
                            },
                            {
                                src: require('@/assets/countriesLogo/bb.svg'),
                                dsf: 143541,
                                country: '巴巴多斯',
                                countryEN: 'Barbados',
                            },
                            {
                                src: require('@/assets/countriesLogo/bz.svg'),
                                dsf: 143555,
                                country: '伯利兹',
                                countryEN: 'Belize',
                            },
                            {
                                src: require('@/assets/countriesLogo/bm.svg'),
                                dsf: 143542,
                                country: '百慕大群岛',
                                countryEN: 'Bermuda',
                            },
                            {
                                src: require('@/assets/countriesLogo/bo.svg'),
                                dsf: 143556,
                                country: '玻利维亚',
                                countryEN: 'Bolivia',
                                ad: true,
                            },
                            {
                                src: require('@/assets/countriesLogo/br.svg'),
                                dsf: 143503,
                                country: '巴西',
                                countryEN: 'Brazil',
                                ad: true,
                            },
                            {
                                src: require('@/assets/countriesLogo/ky.svg'),
                                dsf: 143544,
                                country: '开曼群岛',
                                countryEN: 'Cayman Islands',
                            },
                            {
                                src: require('@/assets/countriesLogo/cl.svg'),
                                dsf: 143483,
                                country: '智利',
                                countryEN: 'Chile',
                                ad: true,
                            },
                            {
                                src: require('@/assets/countriesLogo/co.svg'),
                                dsf: 143501,
                                country: '哥伦比亚',
                                countryEN: 'Colombia',
                                ad: true,
                            },
                            {
                                src: require('@/assets/countriesLogo/cr.svg'),
                                dsf: 143495,
                                country: '哥斯达黎加',
                                countryEN: 'Costa Rica',
                                ad: true,
                            },
                            {
                                src: require('@/assets/countriesLogo/dm.svg'),
                                dsf: 143545,
                                country: '多米尼克',
                                countryEN: 'Dominica',
                            },
                            {
                                src: require('@/assets/countriesLogo/do.svg'),
                                dsf: 143508,
                                country: '多米尼加',
                                countryEN: 'República Dominicana',
                            },
                            {
                                src: require('@/assets/countriesLogo/ec.svg'),
                                dsf: 143509,
                                country: '厄瓜多尔',
                                countryEN: 'Ecuador',
                                ad: true,
                            },
                            {
                                src: require('@/assets/countriesLogo/sv.svg'),
                                dsf: 143506,
                                country: '萨尔瓦多',
                                countryEN: 'El Salvador',
                                ad: true,
                            },
                            {
                                src: require('@/assets/countriesLogo/gd.svg'),
                                dsf: 143546,
                                country: '格林纳达',
                                countryEN: 'Grenada',
                            },
                            {
                                src: require('@/assets/countriesLogo/gt.svg'),
                                dsf: 143504,
                                country: '危地马拉',
                                countryEN: 'Guatemala',
                                ad: true,
                            },
                            {
                                src: require('@/assets/countriesLogo/gy.svg'),
                                dsf: 143553,
                                country: '圭亚那',
                                countryEN: 'Guyana',
                            },
                            {
                                src: require('@/assets/countriesLogo/hn.svg'),
                                dsf: 143510,
                                country: '洪都拉斯',
                                countryEN: 'Honduras',
                                ad: true,
                            },
                            {
                                src: require('@/assets/countriesLogo/jm.svg'),
                                dsf: 143511,
                                country: '牙买加',
                                countryEN: 'Jamaica',
                            },
                            {
                                src: require('@/assets/countriesLogo/mx.svg'),
                                dsf: 143468,
                                country: '墨西哥',
                                countryEN: 'Mexico',
                                ad: true,
                            },
                            {
                                src: require('@/assets/countriesLogo/ms.svg'),
                                dsf: 143547,
                                country: '蒙特塞拉特',
                                countryEN: 'Montserrat',
                            },
                            {
                                src: require('@/assets/countriesLogo/ni.svg'),
                                dsf: 143512,
                                country: '尼加拉瓜',
                                countryEN: 'Nicaragua',
                            },
                            {
                                src: require('@/assets/countriesLogo/pa.svg'),
                                dsf: 143485,
                                country: '巴拿马',
                                countryEN: 'Panama',
                                ad: true,
                            },
                            {
                                src: require('@/assets/countriesLogo/py.svg'),
                                dsf: 143513,
                                country: '巴拉圭',
                                countryEN: 'Paraguay',
                                ad: true,
                            },
                            {
                                src: require('@/assets/countriesLogo/pe.svg'),
                                dsf: 143507,
                                country: '秘鲁',
                                countryEN: 'Peru',
                                ad: true,
                            },
                            {
                                src: require('@/assets/countriesLogo/kn.svg'),
                                dsf: 143548,
                                country: '圣基茨和尼维斯',
                                countryEN: 'Saint Kitts & Nevis',
                            },
                            {
                                src: require('@/assets/countriesLogo/lc.svg'),
                                dsf: 143549,
                                country: '圣卢西亚',
                                countryEN: 'Saint Lucia',
                            },
                            {
                                src: require('@/assets/countriesLogo/vc.svg'),
                                dsf: 143550,
                                country: '圣文森特和格林纳丁斯',
                                countryEN: 'Saint Vincent & The Grenadines',
                            },
                            {
                                src: require('@/assets/countriesLogo/sr.svg'),
                                dsf: 143554,
                                country: '苏里南',
                                countryEN: 'Suriname',
                            },
                            {
                                src: require('@/assets/countriesLogo/tt.svg'),
                                dsf: 143551,
                                country: '特立尼达和多巴哥',
                                countryEN: 'Trinidad & Tobago',
                            },
                            {
                                src: require('@/assets/countriesLogo/tc.svg'),
                                dsf: 143552,
                                country: '特克斯和凯科斯群岛',
                                countryEN: 'Turks & Caicos',
                            },
                            {
                                src: require('@/assets/countriesLogo/uy.svg'),
                                dsf: 143514,
                                country: '乌拉圭',
                                countryEN: 'Uruguay',
                            },
                            {
                                src: require('@/assets/countriesLogo/ve.svg'),
                                dsf: 143502,
                                country: '委内瑞拉',
                                countryEN: 'Venezuela',
                            },
                            {
                                src: require('@/assets/countriesLogo/vg.svg'),
                                dsf: 143543,
                                country: '英属维尔京群岛',
                                countryEN: 'Virgin Islands, British',
                            },
                            {
                                src: require('@/assets/countriesLogo/do.svg'),
                                dsf: 143508,
                                country: '多米尼加共和国',
                                countryEN: 'Dominican Republic',
                                ad: true,
                            },
                            {
                                src: require('@/assets/countriesLogo/default.png'),
                                dsf: 111,
                                country: 'Latin America and the Caribbean',
                                countryEN: 'Latin America and the Caribbean',
                            },
                        ],
                    },
                    {
                        title: '美国、加拿大和波多黎各',
                        titleEN: 'The United States, Canada, and Puerto Rico',
                        list: [
                            {
                                src: require('@/assets/countriesLogo/ca.svg'),
                                dsf: 143455,
                                country: '加拿大',
                                countryEN: 'Canada',
                                ad: true,
                            },
                            {
                                src: require('@/assets/countriesLogo/us.svg'),
                                dsf: 143441,
                                country: '美国',
                                countryEN: 'USA',
                                ad: true,
                            },
                        ],
                    },
                    {
                        title: '其他',
                        titleEN: 'Other',
                        list: [
                            {
                                src: require('@/assets/countriesLogo/bd.svg'),
                                dsf: 143490,
                                country: '孟加拉国',
                                countryEN: 'Bangladesh',
                            },
                            {
                                src: require('@/assets/countriesLogo/kt.svg'),
                                dsf: 143527,
                                country: '科特迪瓦',
                                countryEN: "Cote D'Ivoire",
                            },
                            {
                                src: require('@/assets/countriesLogo/li.svg'),
                                dsf: 143522,
                                country: '列支敦士登',
                                countryEN: 'Liechtenstein',
                            },
                            {
                                src: require('@/assets/countriesLogo/mv.svg'),
                                dsf: 143488,
                                country: '马尔代夫',
                                countryEN: 'Maldives',
                            },
                            {
                                src: require('@/assets/countriesLogo/rs.svg'),
                                dsf: 143500,
                                country: '塞尔维亚',
                                countryEN: 'Serbia',
                            },
                        ],
                    },
                ],
            };
        },
        created() {
            const u = navigator.userAgent;
            // 是否是ios终端
            let isIos = /iPhone|iPad|iPod/i.test(navigator.userAgent);

            // 由于ipad mini以上的设备，navigator.userAgent获取来不是ipad，所以加上Mac判断npm
            if (isIos || u.indexOf('Mac') >= 0) {
                this.userStatus = true;
            } else {
                this.userStatus = false;
            }
        },
        mounted() {
            this.sortedCountries();
            this.countries.forEach((item) => {
                this.restaurants.push(...item.list);
            });
        },
        methods: {
            querySearch(queryString) {
                if (queryString) {
                    var restaurants = [];
                    restaurants = this.restaurants;
                    let countryArray = restaurants.filter((el) => {
                        var m = pinyin.match(el.country, queryString) || pinyin.match(el.countryEN, queryString);

                        return (
                            el.country.toLowerCase().indexOf(queryString.toLowerCase()) >= 0 ||
                            el.countryEN.toLowerCase().indexOf(queryString.toLowerCase()) >= 0 ||
                            m
                        );
                    });

                    this.countryArray = countryArray.slice(0, 5);
                } else {
                    this.countryArray = [];
                }
            },
            skip(dsf) {
                window.location.href = `https://itunes.apple.com/WebObjects/MZStore.woa/wa/resetAndRedirect?dsf=${dsf}&mt=8&url=/WebObjects/MZStore.woa/wa/viewSoftware?mt=8&id=1108187390&cc=hk&urlDesc=`;
                // this.$router.push(`/app/today/${dsf}`);
            },
            showMessage() {
                this.$message('请使用iphone打开');
            },
            changeLanguage() {
                this.lang = this.lang === 'EN' ? '中' : 'EN';
                this.sortedCountries();
            },
            sortedCountries() {
                let sortColumn = this.lang === 'EN' ? 'countryEN' : 'country';
                for (let i = 0; i < this.countries.length; i++) {
                    this.countries[i].list.sort((a, b) => {
                        let x = a[sortColumn];
                        let y = b[sortColumn];
                        return x.localeCompare(y);
                    });
                }
            },
        },
    };
</script>
<style lang="scss">
    html,
    * {
        margin: 0;
        padding: 0;
    }
    body {
        background: #fff;
    }

    .app-today-container {
        max-width: 1200px;
        margin: 15px auto;
    }

    .search {
        position: relative;

        .inline-input {
            width: 350px;
            height: 40px;
        }

        .country-array {
            width: 350px;
            position: absolute;
            top: 50px;
            left: 0;
            z-index: 100;
            border: 1px solid black;
            border-radius: 4px;
            border: solid 1px #f4f4f4;
            background: #fff;
            padding: 10px;
            box-sizing: border-box;

            .country-item {
                margin-bottom: 10px;
                display: flex;
                align-items: center;

                .icon {
                    width: 24px;
                    vertical-align: -7px;
                    margin-right: 10px;
                }
            }
        }
    }

    .content {
        display: flex;
        flex-wrap: wrap;
    }

    .header {
        margin: 30px 0 20px;
        text-align: center;
    }

    .language {
        display: flex;
        width: 60px;
        margin: 0 auto 20px;
        border-radius: 4px;
        border: 1px solid #eee;
        overflow: hidden;
        font-size: 12px;
        text-align: center;
        .item {
            width: 30px;
            height: 24px;
            line-height: 24px;
            cursor: pointer;
            &:last-child {
                border-left: 1px solid #eee;
            }
            &.active {
                background: #ff8800;
                color: #fff;
            }
        }
    }

    .title {
        width: 100%;
        margin-bottom: 20px;
        padding-bottom: 10px;
        border-bottom: 1px solid #ddd;
        text-align: left;
        font-size: 18px;
    }

    .country-list {
        width: 100%;
        list-style: none;
        li {
            float: left;
            width: 33.3%;
            height: 30px;
            margin-bottom: 30px;
            font-size: 14px;

            a {
                display: block;
                color: #30384d;
                text-decoration: none;
                &:hover {
                    color: #ff8800;
                }
            }
        }
        .ad-flag {
            display: inline-block;
            width: 20px;
            height: 12px;
            margin: 5px 0 0 6px;
            background: url('../../assets/data/ad.svg') center / 100% auto no-repeat;
            border-radius: 10px;
            vertical-align: -1px;
            &:hover {
                box-shadow: 0 0 8px 0 rgba(251, 102, 74, 0.4);
            }
            &:before {
                content: '';
            }
        }
    }

    .mt10 {
        margin-top: 10px;
    }

    .country-flag {
        margin-right: 10px;
        vertical-align: middle;
    }

    .cutoff {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        word-wrap: break-word;
    }

    .el-message {
        min-width: 200px;
    }

    .container {
        width: 1200px;
        margin: 0 auto;
    }

    .pl15 {
        padding-left: 15px;
    }

    .copyright {
        background: #222;
        border-top: 1px solid #333;
        line-height: 60px;
        font-size: 12px;
        color: #888;

        a {
            color: #888;
            text-decoration: none;
        }
    }

    @media screen and (max-width: 750px) {
        .app-today-container {
            margin: 15px;
        }

        .country-list {
            li {
                width: 100%;
            }
        }

        .container {
            width: auto;
        }

        .copyright {
            margin: 0;
            padding: 10px 15px;
            border: 0;
            background: #111;
            line-height: 18px;
            font-size: 12px;
        }
    }
</style>

<style>
    @media screen and (max-width: 1200px) {
        #app {
            width: 100%;
        }
    }
</style>
